import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import BannerBasic from "../components/Content/Banner/Basic";
import FeatureColumn from "../components/Content/FeatureColumn/Index";
import LeadIn from "../components/Content/LeadIn/Index";

// data
import data from "../pages/static-pages/content/resources.yaml"

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Resources"
        description="Get answers quick. Check out our frequently asked questions"
      />
      <BannerBasic title="Resources" />
      <FeatureColumn
        grid="medium"
        data={data.columns}
      />
      <LeadIn
        section="primary"
        brandMediaUrl="/icons/icon-search-white.svg"
        brandMediaTitle="Download"
        brandMediaWidth="69px"
        summary="Find out more about the labor saving >B< Press products."
        paddingSize="small"
        buttonValue="Find out more"
        buttonUrl="/products/b-press"
      />
    </Layout>
  );
};

export default IndexPage;
